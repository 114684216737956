









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import i18n from "@/i18n";
import PageButton from "@/views/components/common/buttons/page/PageButton.vue";
import { Location } from "vue-router";

@Component({
  components: { PageButton },
})
export default class PageRefreshButton extends Vue {
  @Prop({ default: i18n.t("actions.refresh") }) text!: string;
}
