var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i-table',{attrs:{"headers":_vm.headers,"items":_vm.reportItems,"sort-by":_vm.sortBy,"sort-desc":"","loading":_vm.loading,"title":_vm.$tc('classSubjectPartReport', 2),"expandable-title":_vm.expandableTitle,"show-select":_vm.showSelect,"selectable-key":"isAvailable","toolbar-buttons":_vm.buttonVOs},on:{"button-event:refresh":function($event){return _vm.$emit('refresh')}},scopedSlots:_vm._u([{key:"item.classSubject",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: _vm.$routes.CLASS_SUBJECT_SHOW,
        params: {
          class_subject_id: item.report.classSubjectPart.classSubject.id,
        },
      }}},[_vm._v(_vm._s(item.classSubject)+" ")])]}},{key:"item.completed_at",fn:function(ref){
      var item = ref.item;
return [_c('boolean-icon',{attrs:{"value":!!item.completed_at}}),_vm._v(" "+_vm._s(item.completed_at)+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('table-item-button',{attrs:{"icon":"mdi-pdf-box","tooltip":_vm.$t('actions.download'),"disabled":!item.isAvailable,"href":item.url}})]}}]),model:{value:(_vm.selectedReportItems),callback:function ($$v) {_vm.selectedReportItems=$$v},expression:"selectedReportItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }