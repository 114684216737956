





















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import i18n from "@/i18n";
import IClassSubjectPartReportDataService from "@/services/IClassSubjectPartReportDataService";
import BooleanIcon from "@/views/components/common/BooleanIcon.vue";
import ClassSubjectPartReport from "@/models/ClassSubjectPartReport";
import ITable from "@/views/components/common/table/ITable.vue";
import { getDownloadButton, refreshButtonVO } from "@/models/vos/ButtonVO";
import { GradingModes } from "@/enums/GradingModes";
import TableItemButton from "@/views/components/common/buttons/tableItem/TableItemButton.vue";

@Component({
  components: {
    TableItemButton,
    BooleanIcon,
    ITable,
  },
})
export default class ClassSubjectReportTable extends Vue {
  @lazyInject(TYPES.IClassSubjectPartReportDataService)
  reportDataService!: IClassSubjectPartReportDataService;
  @Prop({ default: () => [] }) reports!: ClassSubjectPartReport[];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: true }) showClassSubject!: boolean;
  @Prop({ default: true }) showClassSubjectPart!: boolean;
  @Prop({ default: false }) expandableTitle!: boolean;
  @Prop({ default: true }) showSelect!: boolean;
  @Prop({ default: false }) showTerm!: boolean;
  @Prop({ default: "" }) downloadFileNameSuffix!: string;

  protected selectedReportItems: {
    id: string;
    report: ClassSubjectPartReport;
  }[] = [];

  protected get buttonVOs() {
    return [
      refreshButtonVO,
      getDownloadButton(
        `/files?${this.selectedReportsQuery}&name=${this.$tc(
          "classSubjectPartReport",
          2
        )}-${this.downloadFileNameSuffix}`,
        this.selectedReportItems.length <= 0,
        this.$t("actions.download").toString()
      ),
    ];
  }

  protected get selectedReportsQuery(): string {
    return this.selectedReportItems
      .map((item) => `ids[]=${item.report.document?.id}`)
      .join("&");
  }

  protected get reportItems() {
    return this.reports.map((report) => {
      let result = "--/--";
      switch (report.grade_mode) {
        case GradingModes.DIF_AND_STAGE:
          result = report.difficulty?.denomination ?? "-" + report.stage;
          break;
        case GradingModes.GRADES:
          result = `${report.grade}`;
          break;
      }
      return {
        id: report.id,
        completed_at: this.$formatDate(report.completed_at),
        result: result,
        term: report.classSubjectPart?.term ?? "",
        workHabit: report.work_habit,
        socialBehavior: report.social_behavior,
        student: report.student?.full_name,
        classSubjectPart:
          report?.classSubjectPart?.subjectPart?.denomination ?? "",
        classSubject:
          report?.classSubjectPart?.subjectPart?.subject.schoolSubject
            .denomination ?? "",
        url: report.document?.url,
        isAvailable: this.isReportAvailable(report),
        report,
      };
    });
  }

  protected get headers() {
    const headers: any[] = [];

    if (this.showTerm) {
      headers.push({
        text: i18n.tc("term", 1),
        align: "left",
        sortable: true,
        value: "term",
      });
    }
    if (this.showClassSubject) {
      headers.push({
        text: i18n.tc("classSubject", 1),
        align: "left",
        sortable: true,
        value: "classSubject",
      });
    }
    if (this.showClassSubjectPart) {
      headers.push({
        text: i18n.tc("classSubjectPart", 1),
        align: "left",
        sortable: true,
        value: "classSubjectPart",
      });
    }

    headers.push(
      {
        text: i18n.t("completedAt"),
        align: "left",
        sortable: true,
        value: "completed_at",
      },
      {
        text: i18n.tc("result", 1),
        align: "left",
        sortable: true,
        value: "result",
      },
      {
        text: i18n.t("workHabitShort"),
        align: "left",
        sortable: true,
        value: "workHabit",
      },
      {
        text: i18n.t("socialBehaviorShort"),
        align: "left",
        sortable: true,
        value: "socialBehavior",
      },
      {
        align: "right",
        sortable: false,
        value: "action",
      }
    );
    return headers;
  }

  protected get sortBy() {
    let sortBy: string | null;
    if (this.showClassSubject) {
      sortBy = "classSubject";
    } else if (this.showClassSubjectPart) {
      sortBy = "classSubjectPart";
    } else {
      sortBy = null;
    }

    return sortBy;
  }

  protected isReportAvailable(report: ClassSubjectPartReport) {
    return !!(report.completed_at && report.document?.url);
  }
}
