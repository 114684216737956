












































































import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import SearchField from "@/views/components/common/SearchField.vue";
import PageRefreshButton from "@/views/components/common/buttons/page/PageRefreshButton.vue";
import ButtonVO from "@/models/vos/ButtonVO";
import IToolbar from "@/views/components/common/toolbar/IToolbar.vue";
import ExpandWithToolbarFlat from "@/views/components/common/ExpandWithToolbarFlat.vue";

@Component({
  components: {
    ExpandWithToolbarFlat,
    IToolbar,
    PageRefreshButton,
    SearchField,
  },
})
export default class ITable<T> extends Vue {
  @Model("changed") selected!: T[];
  @Prop({ default: () => [] }) items!: T[];
  @Prop({ default: () => [] }) headers!: any[];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: () => "", type: [String, Object] }) title!: string;
  @Prop({ default: () => undefined, type: [String, Object] }) sortBy!:
    | (() => string)
    | undefined;
  @Prop({ default: () => [] }) toolbarButtons!: ButtonVO[];
  @Prop({ default: false }) expandableTitle!: boolean;
  @Prop({ default: false }) sortDesc!: boolean;
  @Prop({ default: true }) expandOnStart!: boolean;
  @Prop({ default: false }) showExpand!: boolean;
  @Prop({ default: false }) showSelect!: boolean;
  @Prop({ default: true }) showSearch!: boolean;
  @Prop({ default: false }) dense!: boolean;
  @Prop({ default: "isSelectable" }) selectableKey!: string;

  protected search = "";

  protected onButtonEvent(event: string) {
    this.$emit(`button-event:${event}`);
  }

  protected set selectedItems(items: T[]) {
    this.$emit("changed", items);
  }

  protected get selectedItems() {
    return this.selected;
  }
}
